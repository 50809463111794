.section1 {
  position: relative;
  z-index: 1;
  height: 100vh;
}

.section1 .swiper {
  height: 100%;
}

.section1 .swiper .swiper-slide {
  overflow: hidden;
}

.section1 .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.section1 .swiper .swiper-slide .slide-inner {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.section1 .swiper .swiper-slide .slide-inner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section1 .swiper .swiper-pagination {
  bottom: 0.7rem;
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  border-style: solid;
  border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all .3s;
  margin: 0 0.15rem;
  border-width: 0.01rem;
  width: 0.18rem;
  height: 0.18rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    border-width: 1px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 18px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 18px;
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #fff;
  width: 0.1rem;
  height: 0.1rem;
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 10px;
  }
}

@media (max-width: 1024px) {
  .section1 .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 10px;
  }
}

.section1 .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #fff;
}

.section1 .scroll-icon {
  border: 2px solid #fff;
  position: absolute;
  z-index: 10;
  border-radius: 50px;
  overflow: hidden;
  transition: all .3s;
  transform: translateX(-50%);
  bottom: 5%;
  left: 50%;
  width: 18px;
  height: 24px;
}

@media (max-width: 1024px) {
  .section1 .scroll-icon {
    display: none;
  }
}

.section1 .scroll-icon::after {
  content: '';
  display: block;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  animation: rain 2s infinite;
  width: 2px;
  height: 6px;
}

.section1 .scroll-icon.hide {
  opacity: 0;
}

@keyframes rain {
  0% {
    transform: translateY(3px);
    opacity: 1;
  }
  80% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.section2 {
  position: relative;
  z-index: 2;
  opacity: 0;
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  transform: translateY(10%);
  margin-bottom: -0.1rem;
}

.section2 .cont .cont1 {
  background-color: #00b0ec;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  position: relative;
  padding-left: 24%;
  padding-right: 0.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

@media (max-width: 767px) {
  .section2 .cont .cont1 {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 {
    padding-top: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 {
    padding-bottom: 0.4rem;
  }
}

.section2 .cont .cont1 .left-img {
  position: absolute;
  opacity: 0;
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  bottom: 0.6rem;
  left: 4.8%;
  width: 15.64%;
  transform: translateY(10%);
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .left-img {
    display: none;
  }
}

.section2 .cont .cont1 .left-img img {
  width: 100%;
  height: auto;
  display: block;
}

.section2 .cont .cont1 .right-swiper {
  position: relative;
  padding-right: 0.85rem;
  padding-left: 0;
}

@media (max-width: 1024px) {
  .section2 .cont .cont1 .right-swiper {
    padding-right: 0.95rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .cont1 .right-swiper {
    padding-right: 1.4rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper {
    padding-right: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper {
    padding-left: 1rem;
  }
}

.section2 .cont .cont1 .right-swiper .swiper {
  overflow: hidden;
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide {
  height: auto;
  background-color: #008cd6;
  color: #fff;
  border-radius: .1rem;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-icon {
  transition: all .3s;
  transform: translateY(5%);
  padding: 0.15rem 0;
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-icon .iconfont {
  font-size: 0.8rem;
}

@media (max-width: 1024px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-icon .iconfont {
    font-size: 0.9rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-icon .iconfont {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-icon .iconfont {
    font-size: 0.8rem;
  }
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text {
  position: relative;
  z-index: 1;
  padding: 0.15rem 0;
  font-size: 0.2rem;
}

@media (max-width: 991px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text {
    padding: 0.2rem 0;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text {
    padding: 0.15rem 0;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text {
    font-size: 0.32rem;
  }
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #e8d000, #ffe608 23%, #ffe60a 58%, #ce912c);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide .item-text > div {
  transition: all .3s;
  transform: translateY(-50%);
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide.active .item-icon {
  transform: translateY(0);
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide.active .item-text::after {
  top: 0;
}

.section2 .cont .cont1 .right-swiper .swiper .swiper-slide.active .item-text > div {
  transform: translateY(0);
  color: #008cd6;
}

.section2 .cont .cont1 .right-swiper .swiper-btn {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transform: translateY(-50%);
  position: absolute;
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper-btn {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper-btn {
    transform: translateY(0);
  }
}

.section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-next, .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-prev {
  border-color: #fff;
  margin: 0;
  transform: translateY(-50%);
  position: relative;
  transform: translateY(0);
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-next, .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-prev {
    position: absolute;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-next, .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-prev {
    transform: translateY(-50%);
  }
}

.section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-next .icon::after, .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-prev .icon::after {
  color: #fff;
}

.section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-next {
  right: auto;
  margin-bottom: 0.15rem;
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-next {
    right: 0.1rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-next {
    margin-bottom: 0;
  }
}

.section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-prev {
  left: auto;
}

@media (max-width: 767px) {
  .section2 .cont .cont1 .right-swiper .swiper-btn .swiper-button-prev {
    left: 0.1rem;
  }
}

.section2 .cont .cont2 {
  overflow: hidden;
  background-color: #c3eaff;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.section2 .cont .cont2 .swiper .swiper-slide {
  align-items: stretch;
  justify-content: space-between;
  height: auto;
  display: flex;
}

@media (max-width: 991px) {
  .section2 .cont .cont2 .swiper .swiper-slide {
    display: block;
  }
}

.section2 .cont .cont2 .swiper .swiper-slide .item-text {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 46%;
  padding: 1.1rem 1.2rem;
}

@media (max-width: 991px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-text {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-text {
    padding: 0.5rem;
  }
}

.section2 .cont .cont2 .swiper .swiper-slide .item-text .title {
  font-weight: bold;
  font-size: 0.5rem;
  margin-bottom: 0.45rem;
}

@media (max-width: 1024px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-text .title {
    font-size: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-text .title {
    font-size: 0.76rem;
  }
}

@media (max-width: 767px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-text .title {
    font-size: 0.5rem;
  }
}

.section2 .cont .cont2 .swiper .swiper-slide .item-text .desc {
  line-height: 1.9;
  margin-bottom: 0.45rem;
}

.section2 .cont .cont2 .swiper .swiper-slide .item-text .link {
  text-align: left;
}

@media (max-width: 991px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-text .link {
    text-align: right;
  }
}

.section2 .cont .cont2 .swiper .swiper-slide .item-img {
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 0.1rem;
  display: flex;
  min-height: 28.12vw;
  width: 54%;
}

@media (max-width: 991px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-img {
    padding-top: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section2 .cont .cont2 .swiper .swiper-slide .item-img {
    width: 100%;
  }
}

.section2 .cont .cont2 .swiper .swiper-slide .item-img img {
  display: block;
  height: auto;
  width: 104%;
}

.section2.is-inview {
  opacity: 1;
  transition-delay: .3s;
  transform: translateY(-0.9rem);
}

.section2.is-inview .cont .cont1 .left-img {
  opacity: 1;
  transition-delay: 1s;
  transform: translateY(0);
}

.section3 .cont {
  border-radius: .3rem;
  overflow: hidden;
  background-color: #dbf0fb;
  flex-direction: row-reverse;
  display: flex;
}

@media (max-width: 991px) {
  .section3 .cont {
    display: block;
  }
}

.section3 .cont .sec3-text {
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 44.18%;
  padding: 0.95rem 0.8rem;
}

@media (max-width: 991px) {
  .section3 .cont .sec3-text {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section3 .cont .sec3-text {
    padding: 0.5rem;
  }
}

.section3 .cont .sec3-text .tag {
  margin-bottom: 0.15rem;
}

.section3 .cont .sec3-text .tag span {
  display: inline-block;
  color: #00b0ec;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  padding: 0.1rem 0.2rem;
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section3 .cont .sec3-text .tag span {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .sec3-text .tag span {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .sec3-text .tag span {
    font-size: 0.26rem;
  }
}

.section3 .cont .sec3-text .title {
  font-weight: bold;
  margin-bottom: 0.4rem;
  font-size: 0.6rem;
}

@media (max-width: 1024px) {
  .section3 .cont .sec3-text .title {
    font-size: 0.7rem;
  }
}

@media (max-width: 991px) {
  .section3 .cont .sec3-text .title {
    font-size: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section3 .cont .sec3-text .title {
    font-size: 0.48rem;
  }
}

.section3 .cont .sec3-text .desc {
  line-height: 1.9;
  margin-bottom: 0.5rem;
}

.section3 .cont .sec3-text .link {
  text-align: left;
}

@media (max-width: 991px) {
  .section3 .cont .sec3-text .link {
    text-align: right;
  }
}

.section3 .cont .sec3-text .circle-bg-img {
  z-index: -1;
  top: 0%;
  right: 0%;
}

.section3 .cont .sec3-img {
  flex-shrink: 0;
  width: 55.82%;
  min-height: 36.45vw;
}

@media (max-width: 991px) {
  .section3 .cont .sec3-img {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section3 .cont .sec3-img {
    min-height: 55vw;
  }
}

@media (max-width: 767px) {
  .section3 .cont .sec3-img {
    min-height: 60vw;
  }
}

.section4 {
  margin-top: 0.8rem;
  margin-bottom: 1.4rem;
}

@media (max-width: 767px) {
  .section4 {
    margin-bottom: 0.8rem;
  }
}

.section4 .cont {
  background-color: #dbf0fb;
  border-radius: .3rem;
  overflow: hidden;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.9rem;
  padding-right: 0.6rem;
}

@media (max-width: 767px) {
  .section4 .cont {
    padding-top: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section4 .cont {
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section4 .cont {
    padding-left: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section4 .cont {
    padding-right: 0.5rem;
  }
}

.section4 .cont::after {
  content: '';
  width: 80%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background: url(../../assets/images/circle3.png) no-repeat top left;
  background-size: contain;
  opacity: 0;
  transform: translate3d(-10%, -10%, 0) scale(0.8);
  transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: left bottom;
  top: -10%;
  left: 0;
  width: 13.35rem;
  height: 8.16rem;
}

@media (max-width: 767px) {
  .section4 .cont::after {
    width: 6.35rem;
  }
}

@media (max-width: 767px) {
  .section4 .cont::after {
    height: 4.16rem;
  }
}

.section4 .cont .common-title {
  position: relative;
  z-index: 1;
}

.section4 .cont .common-title .swiper-btn {
  display: flex;
  align-items: center;
}

.section4 .cont .common-title .swiper-btn .swiper-button-prev, .section4 .cont .common-title .swiper-btn .swiper-button-next {
  position: relative;
  margin-top: 0;
  left: auto;
  right: auto;
}

.section4 .cont .common-title .swiper-btn .swiper-button-prev {
  margin-right: 0.15rem;
}

.section4 .cont .swiper {
  overflow: visible;
}

.section4 .cont .swiper .swiper-slide {
  height: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: .2rem;
  position: relative;
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86), box-shadow 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.section4 .cont .swiper .swiper-slide:hover {
  transform: translate3d(-0.02rem, -0.05rem, 0);
  box-shadow: 0.04rem 0.08rem 0.15rem #c8eafa;
}

.section4 .cont .swiper .swiper-slide::after {
  content: '';
  display: block;
  background-color: #c4deec;
  border-radius: 50%;
  position: absolute;
  top: 0.15rem;
  left: 0.15rem;
  width: 0.13rem;
  height: 0.13rem;
}

@media (max-width: 1024px) {
  .section4 .cont .swiper .swiper-slide::after {
    top: 13px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont .swiper .swiper-slide::after {
    left: 13px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont .swiper .swiper-slide::after {
    width: 10px;
  }
}

@media (max-width: 1024px) {
  .section4 .cont .swiper .swiper-slide::after {
    height: 10px;
  }
}

.section4 .cont .swiper .swiper-slide a {
  display: block;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0.3rem;
}

.section4 .cont .swiper .swiper-slide a .img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  margin-bottom: 0.2rem;
}

.section4 .cont .swiper .swiper-slide a .img img {
  display: block;
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 50%;
}

@media (max-width: 1024px) {
  .section4 .cont .swiper .swiper-slide a .img img {
    max-height: 55%;
  }
}

@media (max-width: 991px) {
  .section4 .cont .swiper .swiper-slide a .img img {
    max-height: 60%;
  }
}

@media (max-width: 767px) {
  .section4 .cont .swiper .swiper-slide a .img img {
    max-height: 70%;
  }
}

.section4 .cont .swiper .swiper-slide a .t {
  line-height: 1.4;
}

.section4.is-inview .cont::after {
  transition-delay: 1s;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

.section5 .common-title {
  padding-left: 0.45rem;
  padding-right: 0.35rem;
  margin-bottom: 0.35rem;
}

.section5 .common-title a {
  color: #00b0ec;
  transition: color .3s;
}

.section5 .common-title a:hover {
  color: #163f57;
}

.section5 ul li {
  position: relative;
  padding: 0 0.45rem;
}

.section5 ul li:not(:last-child) a {
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 140, 214, 0.2);
  border-bottom-width: 0.01rem;
}

@media (max-width: 1024px) {
  .section5 ul li:not(:last-child) a {
    border-bottom-width: 1px;
  }
}

.section5 ul li::after {
  content: '';
  display: block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  border-radius: .2rem;
  transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  width: calc(100% - .9rem);
  height: calc(100% - .5rem);
}

.section5 ul li a {
  align-items: center;
  position: relative;
  transition: border-bottom-color 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  justify-content: flex-start;
  padding: 0.25rem 0;
  display: flex;
}

@media (max-width: 1024px) {
  .section5 ul li a {
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .section5 ul li a {
    padding: 0.35rem 0;
  }
}

@media (max-width: 767px) {
  .section5 ul li a {
    display: block;
  }
}

.section5 ul li a .item-img {
  flex-shrink: 0;
  border-radius: .1rem;
  overflow: hidden;
  height: 8.33vw;
  width: 16.4%;
  margin-right: 0.6rem;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .section5 ul li a .item-img {
    height: 10.5vw;
  }
}

@media (max-width: 991px) {
  .section5 ul li a .item-img {
    height: 13.5vw;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-img {
    height: 50vw;
  }
}

@media (max-width: 1024px) {
  .section5 ul li a .item-img {
    width: 20%;
  }
}

@media (max-width: 991px) {
  .section5 ul li a .item-img {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section5 ul li a .item-img {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-img {
    margin-bottom: 0.3rem;
  }
}

.section5 ul li a .item-img b {
  display: block;
  width: 100%;
  height: 100%;
}

.section5 ul li a .item-text {
  flex-shrink: 0;
  width: 60.7%;
}

@media (max-width: 1024px) {
  .section5 ul li a .item-text {
    width: 76%;
  }
}

@media (max-width: 991px) {
  .section5 ul li a .item-text {
    width: 71%;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-text {
    width: 100%;
  }
}

.section5 ul li a .item-text .mob-flex {
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  display: block;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .mob-flex {
    display: flex;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .mob-flex {
    margin-bottom: 0.2rem;
  }
}

.section5 ul li a .item-text .date {
  color: #00b0ec;
  margin-bottom: 0.2rem;
  font-size: 0.16rem;
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .date {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .section5 ul li a .item-text .date {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section5 ul li a .item-text .date {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .date {
    font-size: 0.26rem;
  }
}

.section5 ul li a .item-text .tag {
  margin-bottom: 0.25rem;
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .tag {
    margin-bottom: 0;
  }
}

.section5 ul li a .item-text .tag span {
  display: inline-block;
  color: #00b0ec;
  background-color: #dff2fb;
  border-radius: 50px;
  padding: 0.1rem 0.2rem;
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section5 ul li a .item-text .tag span {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section5 ul li a .item-text .tag span {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .tag span {
    font-size: 0.26rem;
  }
}

.section5 ul li a .item-text .title {
  text-overflow: ellipsis;
  line-height: nul;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.22rem;
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .title {
    line-height: 1.4;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .title {
    white-space: wrap;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .title {
    overflow: visible;
  }
}

@media (max-width: 1024px) {
  .section5 ul li a .item-text .title {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .section5 ul li a .item-text .title {
    font-size: 0.42rem;
  }
}

@media (max-width: 767px) {
  .section5 ul li a .item-text .title {
    font-size: 0.3rem;
  }
}

.section5 ul li a .item-btn {
  position: absolute;
  top: 50%;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translateY(-50%) scale(0.8);
  right: 0.2rem;
}

@media (max-width: 1024px) {
  .section5 ul li a .item-btn {
    display: none;
  }
}

.section5 ul li a .item-btn .common-btn::after {
  opacity: 1;
  transform: scale(1);
}

.section5 ul li a .item-btn .common-btn .icon::before, .section5 ul li a .item-btn .common-btn .icon::after {
  transform: translateX(100%);
}

.section5 ul li:hover::after {
  opacity: 1;
  width: 100%;
  height: 100%;
  box-shadow: 0.05rem 0.1rem 0.2rem #dff2fb;
}

.section5 ul li:hover a {
  border-bottom-color: transparent;
}

.section5 ul li:hover a .item-btn {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.section6 {
  align-items: stretch;
  justify-content: space-between;
  display: flex;
  margin-top: 1.5rem;
}

@media (max-width: 991px) {
  .section6 {
    display: block;
  }
}

@media (max-width: 767px) {
  .section6 {
    margin-top: 0.6rem;
  }
}

.section6 .sec6-left {
  flex-shrink: 0;
  display: block;
  position: relative;
  width: 48.43%;
  height: 23.44vw;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section6 .sec6-left {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section6 .sec6-left {
    height: 40vw;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-left {
    height: 50vw;
  }
}

@media (max-width: 991px) {
  .section6 .sec6-left {
    margin-bottom: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-left {
    margin-bottom: 0.4rem;
  }
}

.section6 .sec6-left .t {
  position: absolute;
  background-image: linear-gradient(to right, rgba(243, 250, 254, 0.95), rgba(243, 250, 254, 0.7), rgba(243, 250, 254, 0));
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
  z-index: 1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  padding: 1.15rem 0.55rem;
  width: 60%;
}

@media (max-width: 1024px) {
  .section6 .sec6-left .t {
    padding: 0.8rem 0.5rem;
  }
}

@media (max-width: 991px) {
  .section6 .sec6-left .t {
    padding: 1.5rem 0.7rem;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-left .t {
    padding: 0.5rem;
  }
}

.section6 .sec6-left .t .title {
  font-weight: bold;
  font-size: 0.32rem;
  margin-bottom: 0.25rem;
}

@media (max-width: 1024px) {
  .section6 .sec6-left .t .title {
    font-size: 0.42rem;
  }
}

@media (max-width: 991px) {
  .section6 .sec6-left .t .title {
    font-size: 0.52rem;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-left .t .title {
    font-size: 0.38rem;
  }
}

.section6 .sec6-left .t .desc {
  line-height: 1.9;
}

.section6 .sec6-left .img {
  height: 100%;
  border-radius: .2rem;
  overflow: hidden;
  position: relative;
}

.section6 .sec6-left .img b {
  display: block;
  width: 100%;
  height: 100%;
  transition: all .4s;
}

.section6 .sec6-left:hover .img b {
  transform: scale(1.05);
}

.section6 .sec6-right {
  flex-shrink: 0;
  border-radius: .2rem;
  overflow: hidden;
  display: flex;
  width: 48.43%;
  height: 23.44vw;
}

@media (max-width: 991px) {
  .section6 .sec6-right {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section6 .sec6-right {
    height: 40vw;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-right {
    height: 50vw;
  }
}

.section6 .sec6-right .t {
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  color: #fff;
  background-color: #008cd6;
  width: 35.2%;
  padding: 1.15rem 0 1.15rem 0.55rem;
}

@media (max-width: 1024px) {
  .section6 .sec6-right .t {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-right .t {
    width: 51%;
  }
}

@media (max-width: 1024px) {
  .section6 .sec6-right .t {
    padding: 0.8rem 0 0.8rem 0.5rem;
  }
}

@media (max-width: 991px) {
  .section6 .sec6-right .t {
    padding: 1.5rem 0 1.5rem 0.7rem;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-right .t {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.section6 .sec6-right .t::after {
  content: '';
  display: block;
  height: 100%;
  background: url(./images/index_img4.png) no-repeat right center;
  background-size: auto 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 140%;
}

@media (max-width: 767px) {
  .section6 .sec6-right .t::after {
    width: 130%;
  }
}

.section6 .sec6-right .t .title {
  font-weight: bold;
  font-size: 0.32rem;
  margin-bottom: 0.25rem;
}

@media (max-width: 1024px) {
  .section6 .sec6-right .t .title {
    font-size: 0.42rem;
  }
}

@media (max-width: 991px) {
  .section6 .sec6-right .t .title {
    font-size: 0.52rem;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-right .t .title {
    font-size: 0.38rem;
  }
}

.section6 .sec6-right .t .desc {
  line-height: 1.9;
}

@media (max-width: 767px) {
  .section6 .sec6-right .t .desc {
    line-height: 1.6;
  }
}

.section6 .sec6-right .img {
  flex-shrink: 0;
  height: 100%;
  width: 64.8%;
}

@media (max-width: 1024px) {
  .section6 .sec6-right .img {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .section6 .sec6-right .img {
    width: 49%;
  }
}

.section6 .sec6-right .img b {
  display: block;
  width: 100%;
  height: 100%;
  transition: all .4s;
}

.section6 .sec6-right:hover .img b {
  transform: scale(1.05);
}
