@import "../../assets/styles/utils";

.section1{
  position: relative;
  z-index: 1;
  @include res(height,100vh);
  .swiper{
    height: 100%;
    .swiper-slide{
      overflow: hidden;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.2);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .slide-inner{
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        video{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .swiper-pagination{
      @include res(bottom,.7rem);
      .swiper-pagination-bullet{
        background-color: transparent;
        border-style: solid;
        border-color: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: all .3s;
        @include res(margin,0 .15rem);
        @include res(border-width,.01rem,(md:1px));
        @include res(width,.18rem,(md:18px));
        @include res(height,.18rem,(md:18px));
        &::after{
          content: '';
          display: block;
          border-radius: 50%;
          background-color: #fff;
          @include res(width,.1rem,(md:10px));
          @include res(height,.1rem,(md:10px));
        }
        &.swiper-pagination-bullet-active{
          border-color: #fff;
        }
      }
    }
  }
  .scroll-icon{
    border: 2px solid #fff;
    position: absolute;
    z-index: 10;
    border-radius: 50px;
    overflow: hidden;
    transition: all .3s;
    @include res(transform,translateX(-50%));
    @include res(display,null,(md:none));
    @include res(bottom,5%);
    @include res(left,50%);
    @include res(width,18px);
    @include res(height,24px);
    &::after{
      content: '';
      display: block;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      animation: rain 2s infinite;
      @include res(width,2px);
      @include res(height,6px);
    }
    &.hide{
      opacity: 0;
    }
  }
  @keyframes rain {
    0% {
      transform: translateY(3px);
      opacity: 1;
    }
    80% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }
}

.section2{
  position: relative;
  z-index: 2;
  opacity: 0;
  transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1),
              opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  @include res(transform,translateY(10%));
  @include res(margin-bottom,-.1rem);
  .cont{
    .cont1{
      background-color: #00b0ec;
      border-top-left-radius: .3rem;
      border-top-right-radius: .3rem;
      position: relative;
      @include res(padding-left,24%,(xs:0));
      @include res(padding-right,.5rem,(xs:0));
      @include res(padding-top,.6rem,(xs:.4rem));
      @include res(padding-bottom,.6rem,(xs:.4rem));
      .left-img{
        position: absolute;
        opacity: 0;
        transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1),
              opacity 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
        transition-delay: 0s;
        @include res(display,null,(xs:none));
        @include res(bottom,.6rem);
        @include res(left,4.8%);
        @include res(width,15.64%);
        @include res(transform,translateY(10%));
        img{
          width: 100%;
          height: auto;
          display: block;
        }
      }
      .right-swiper{
        position: relative;
        @include res(padding-right,.85rem,(md:.95rem,sm:1.4rem,xs:1rem));
        @include res(padding-left,0,(xs:1rem));
        .swiper{
          overflow: hidden;
          .swiper-slide{
            height: auto;
            background-color: #008cd6;
            color: #fff;
            border-radius: .1rem;
            text-align: center;
            cursor: pointer;
            overflow: hidden;
            .item-icon{
              transition: all .3s;
              transform: translateY(5%);
              @include res(padding,.15rem 0);
              .iconfont{
                @include res(font-size,.8rem,(md:.9rem,sm:1rem,xs:.8rem));
              }
            }
            .item-text{
              position: relative;
              z-index: 1;
              @include res(padding,.15rem 0,(sm:.2rem 0,xs:.15rem 0));
              @include res(font-size,.2rem,$font-20);
              &::after{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to right,$yellow-gradient);
                position: absolute;
                top: 100%;
                left: 0;
                z-index: -1;
                transition: all .3s $anime-bezier;
              }
              >div{
                transition: all .3s;
                transform: translateY(-50%);
              }
            }
            &.active{
              .item-icon{transform: translateY(0);}
              .item-text{
                &::after{
                  top: 0;
                }
                >div{
                  transform: translateY(0);
                  color: #008cd6;
                }
              }
            }
          }
        }
        .swiper-btn{
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          @include res(width,null,(xs:100%));
          @include res(transform,translateY(-50%),(xs:translateY(0)));
          @include res(position,absolute);
          .swiper-button-next,.swiper-button-prev{
            border-color: #fff;
            margin: 0;
            transform: translateY(-50%);
            @include res(position,relative,(xs:absolute));
            @include res(transform,translateY(0),(xs:translateY(-50%)));
            .icon{
              &::after{
                color: #fff;
              }
            }
          }
          .swiper-button-next{
            @include res(right,auto,(xs:.1rem));
            @include res(margin-bottom,.15rem,(xs:0));
          }
          .swiper-button-prev{
            @include res(left,auto,(xs:.1rem));
          }
        }
      }
    }
    .cont2{
      overflow: hidden;
      background-color: #c3eaff;
      border-bottom-left-radius: .3rem;
      border-bottom-right-radius: .3rem;
      .swiper{
        .swiper-slide{
          align-items: stretch;
          justify-content: space-between;
          height: auto;
          @include res(display,flex,(sm:block));
          .item-text{
            flex-shrink: 0;
            box-sizing: border-box;
            @include res(width,46%,(sm:100%));
            @include res(padding,1.1rem 1.2rem,(xs:.5rem));
            .title{
              font-weight: bold;
              @include res(font-size,.5rem,$font-50);
              @include res(margin-bottom,.45rem);
            }
            .desc{
              line-height: 1.9;
              @include res(margin-bottom,.45rem);
            }
            .link{
              @include res(text-align,left,(sm:right));
            }
          }
          .item-img{
            flex-shrink: 0;
            align-items: flex-end;
            justify-content: flex-start;
            @include res(padding-top,.1rem,(sm:.3rem));
            @include res(display,flex);
            @include res(min-height,28.12vw);
            @include res(width,54%,(sm:100%));
            img{
              display: block;
              height: auto;
              @include res(width,104%);
            }
          }
        }
      }
    }
  }
  &.is-inview{
    opacity: 1;
    transition-delay: .3s;
    @include res(transform,translateY(-.9rem));
    .cont{
      .cont1{
        .left-img{
          opacity: 1;
          transition-delay: 1s;
          @include res(transform,translateY(0));
        }
      }
    }
  }
}

.section3{
  .cont{
    border-radius: .3rem;
    overflow: hidden;
    background-color: $color-main-bg;
    flex-direction: row-reverse;
    @include res(display,flex,(sm:block));
    .sec3-text{
      flex-shrink: 0;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      z-index: 1;
      @include res(width,44.18%,(sm:100%));
      @include res(padding,.95rem .8rem,(xs:.5rem));
      .tag{
        @include res(margin-bottom,.15rem);
        span{
          display: inline-block;
          color: $color-main;
          background-color: rgba(255,255,255,.5);
          border-radius: 50px;
          @include res(padding,.1rem .2rem);
          @include res(font-size,.16rem,$font-16);
        }
      }
      .title{
        font-weight: bold;
        @include res(margin-bottom,.4rem);
        @include res(font-size,.6rem,(md:.7rem,sm:.8rem,xs:.48rem));
      }
      .desc{
        line-height: 1.9;
        @include res(margin-bottom,.5rem);
      }
      .link{
        @include res(text-align,left,(sm:right));
      }
      .circle-bg-img{
        z-index: -1;
        top: 0%;
        right: 0%;
      }
    }
    .sec3-img{
      flex-shrink: 0;
      @include res(width,55.82%,(sm:100%));
      @include res(min-height,36.45vw,(sm:55vw,xs:60vw));
    }
  }
}

.section4{
  @include res(margin-top,.8rem);
  @include res(margin-bottom,1.4rem,(xs:.8rem));
  .cont{
    background-color: $color-main-bg;
    border-radius: .3rem;
    overflow: hidden;
    position: relative;
    @include res(padding-top,1rem,(xs:.5rem));
    @include res(padding-bottom,1rem,(xs:.5rem));
    @include res(padding-left,.9rem,(xs:.5rem));
    @include res(padding-right,.6rem,(xs:.5rem));
    &::after{
      content: '';
      width: 80%;
      height: 100%;
      pointer-events: none;
      position: absolute;
      background: url(../../assets/images/circle3.png) no-repeat top left;
      background-size: contain;
      opacity: 0;
      transform: translate3d(-10%,-10%,0) scale(0.8);
      transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1),
              opacity 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
      transform-origin: left bottom;
      @include res(top,-10%);
      @include res(left,0);
      @include res(width,13.35rem,(xs:6.35rem));
      @include res(height,8.16rem,(xs:4.16rem));
    }
    .common-title{
      position: relative;
      z-index: 1;
      .swiper-btn{
        display: flex;
        align-items: center;
        .swiper-button-prev,.swiper-button-next{
          position: relative;
          margin-top: 0;
          left: auto;
          right: auto;
        }
        .swiper-button-prev{
          @include res(margin-right,.15rem);
        }
      }
    }
    .swiper{
      overflow: visible;
      .swiper-slide{
        height: auto;
        background-color: rgba(255,255,255,.8);
        border-radius: .2rem;
        position: relative;
        transition: transform .6s $anime-bezier,box-shadow .6s $anime-bezier;
        &:hover{
          transform: translate3d(-.02rem,-.05rem,0);
          box-shadow: .04rem .08rem .15rem rgb(200, 234, 250);
        }
        &::after{
          content: '';
          display: block;
          background-color: #c4deec;
          border-radius: 50%;
          position: absolute;
          @include res(top,.15rem,(md:13px));
          @include res(left,.15rem,(md:13px));
          @include res(width,.13rem,(md:10px));
          @include res(height,.13rem,(md:10px));
        }
        a{
          display: block;
          height: 100%;
          text-align: center;
          box-sizing: border-box;
          @include res(padding,.3rem);
          .img{
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(height,2rem);
            @include res(margin-bottom,.2rem);
            img{
              display: block;
              width: auto;
              height: auto;
              @include res(max-width,80%);
              @include res(max-height,50%,(md:55%,sm:60%,xs:70%));
            }
          }
          .t{
            line-height: 1.4;
          }
        }
      }
    }
  }
  &.is-inview{
    .cont{
      &::after{
        transition-delay: 1s;
        opacity: 1;
        transform: translate3d(-0,0,0) scale(1);
      }
    }
  }
}

.section5{
  .common-title{
    @include res(padding-left,.45rem);
    @include res(padding-right,.35rem);
    @include res(margin-bottom,.35rem);
    a{
      color: $color-main;
      transition: color .3s;
      &:hover{
        color: $color-main-dark;
      }
    }
  }
  ul{
    li{
      position: relative;
      @include res(padding,0 .45rem);
      &:not(:last-child){
        a{
          border-bottom-style: solid;
          border-bottom-color: rgba(0,140,214,.2);
          @include res(border-bottom-width,.01rem,(md:1px));
        }
      }
      &::after{
        content: '';
        display: block;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        border-radius: .2rem;
        transition: all .5s $anime-bezier;
        transform: translate3d(-50%,-50%,0);
        opacity: 0;
        @include res(width,calc(100% - .9rem));
        @include res(height,calc(100% - .5rem));
      }
      a{
        align-items: center;
        position: relative;
        transition: border-bottom-color .5s $anime-bezier;
        @include res(justify-content,flex-start,(md:space-between));
        @include res(padding,.25rem 0,(md:.35rem 0));
        @include res(display,flex,(xs:block));
        .item-img{
          flex-shrink: 0;
          border-radius: .1rem;
          overflow: hidden;
          @include res(height,8.33vw,(md:10.5vw,sm:13.5vw,xs:50vw));
          @include res(width,16.4%,(md:20%,sm:25%,xs:100%));
          @include res(margin-right,.6rem,(md:0));
          @include res(margin-bottom,0,(xs:.3rem));
          b{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .item-text{
          flex-shrink: 0;
          @include res(width,60.7%,(md:76%,sm:71%,xs:100%));
          .mob-flex{
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            @include res(display,block,(xs:flex));
            @include res(margin-bottom,0,(xs:.2rem));
          }
          .date{
            color: $color-main;
            @include res(margin-bottom,.2rem,(xs:0));
            @include res(font-size,.16rem,$font-16);
          }
          .tag{
            @include res(margin-bottom,.25rem,(xs:0));
            span{
              display: inline-block;
              color: $color-main;
              background-color: #dff2fb;
              border-radius: 50px;
              @include res(padding,.1rem .2rem);
              @include res(font-size,.16rem,$font-16);
            }
          }
          .title{
            text-overflow: ellipsis;
            @include res(line-height,nul,(xs:1.4));
            @include res(white-space,nowrap,(xs:wrap));
            @include res(overflow,hidden,(xs:visible));
            @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.3rem));
          }
        }
        .item-btn{
          position: absolute;
          top: 50%;
          opacity: 0;
          transition: opacity .5s $anime-bezier,transform .5s $anime-bezier;
          transform: translateY(-50%) scale(0.8);
          @include res(display,null,(md:none));
          @include res(right,.2rem);
          .common-btn{
            &::after{
              opacity: 1;
              transform: scale(1);
            }
            .icon{
              &::before,&::after{
                transform: translateX(100%);
              }
            }
          }
        }
      }
      &:hover{
        &::after{
          opacity: 1;
          width: 100%;
          height: 100%;
          box-shadow: .05rem .1rem .2rem rgba(223,242,251,1);
        }
        a{
          border-bottom-color: transparent;
          .item-btn{
            opacity: 1;
            transform: translateY(-50%) scale(1);
          }
        }
      }
    }
  }
}

.section6{
  align-items: stretch;
  justify-content: space-between;
  @include res(display,flex,(sm:block));
  @include res(margin-top,1.5rem,(xs:.6rem));
  .sec6-left{
    flex-shrink: 0;
    display: block;
    position: relative;
    @include res(width,48.43%,(sm:100%));
    @include res(height,23.44vw,(sm:40vw,xs:50vw));
    @include res(margin-bottom,0,(sm:.6rem,xs:.4rem));
    .t{
      position: absolute;
      background-image: linear-gradient(to right,rgba(243,250,254,.95),rgba(243,250,254,.7),rgba(243,250,254,0));
      border-top-left-radius: .2rem;
      border-bottom-left-radius: .2rem;
      z-index: 1;
      @include res(top,-1px);
      @include res(left,-1px);
      @include res(bottom,-1px);
      @include res(padding,1.15rem .55rem,(md:.8rem .5rem,sm:1.5rem .7rem,xs:.5rem));
      @include res(width,60%);
      .title{
        font-weight: bold;
        @include res(font-size,.32rem,$font-32);
        @include res(margin-bottom,.25rem);
      }
      .desc{
        line-height: 1.9;
      }
    }
    .img{
      height: 100%;
      border-radius: .2rem;
      overflow: hidden;
      position: relative;
      b{
        display: block;
        width: 100%;
        height: 100%;
        transition: all .4s;
      }
    }
    &:hover{
      .img b{
        transform: scale(1.05);
      }
    }
  }
  .sec6-right{
    flex-shrink: 0;
    border-radius: .2rem;
    overflow: hidden;
    @include res(display,flex);
    @include res(width,48.43%,(sm:100%));
    @include res(height,23.44vw,(sm:40vw,xs:50vw));
    .t{
      flex-shrink: 0;
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      color: #fff;
      background-color: #008cd6;
      @include res(width,35.2%,(md:40%,xs:51%));
      @include res(padding,1.15rem 0 1.15rem .55rem,(md:.8rem 0 .8rem .5rem,sm:1.5rem 0 1.5rem .7rem,xs:.5rem 0 .5rem .5rem));
      &::after{
        content: '';
        display: block;
        height: 100%;
        background: url(./images/index_img4.png) no-repeat right center ;
        background-size: auto 100%;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        @include res(width,140%,(xs:130%));
      }
      .title{
        font-weight: bold;
        @include res(font-size,.32rem,$font-32);
        @include res(margin-bottom,.25rem);
      }
      .desc{
        @include res(line-height,1.9,(xs:1.6));
      }
    }
    .img{
      flex-shrink: 0;
      height: 100%;
      @include res(width,64.8%,(md:60%,xs:49%));
      b{
        display: block;
        width: 100%;
        height: 100%;
        transition: all .4s;
      }
    }
    &:hover{
      .img b{
        transform: scale(1.05);
      }
    }
  }
}